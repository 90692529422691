import type { ComputedRef, MaybeRef } from 'vue'
import type { MarketingLevyForm, MarketingLevyReviewTable } from '../types'
import { joinPath, resolveRealPath, YearType } from '@manager'
import { convertMarketingLevyToReviewTable } from '@manager/components/Group/Custom/MarketingLevyRentReviewTable/utils/convertMarketingLevyToReviewTable'

export const useTable = (initialValue: MaybeRef<any[]>, objectPath: string) => {
  const dayjs = useDayjs()

  const { formValue } = useManagerFormValue<MarketingLevyForm>()
  const marketingLevy = computed(
    () => formValue.value?.marketingLevy?.data?.[0],
  )
  const leaseStartDate = computed(() => marketingLevy.value?.startDate)
  const leaseEndDate = computed(() => marketingLevy.value?.endDate)

  // TABLE CONFIG
  const joinDataPath = (index: number) => joinPath(objectPath, `data[${index}]`)
  const increaseAsBaseRent = computed(() => marketingLevy.value?.asBaseRent)
  const method = computed(() => marketingLevy.value?.method)
  const increase = computed(() => {
    switch (method.value) {
      case 'FixedAmount':
        return 'marketingLevy.data[0].increaseAmount'
      case 'FixedPercentage':
        return 'marketingLevy.data[0].increasePercentage'
      case 'Cpi':
        return undefined
      case 'CpiPlus':
        return 'marketingLevy.data[0].increaseCpiPlus'
      default:
        return undefined
    }
  })

  const { data, filteredData, addOrUpdate, batchUpdate, remove } =
    useTableData<MarketingLevyReviewTable>(initialValue, {
      path: objectPath,
      initialItem: computed(() => {
        return {
          method: marketingLevy.value?.method,
          fixedAmount: marketingLevy.value?.increaseAmount,
          fixedPercentage: marketingLevy.value?.increasePercentage,
          cpiPercent: marketingLevy.value?.increaseCpiPlus,
          cpiCollar: null,
          cpiCap: null,
          greaterOf: marketingLevy.value?.greaterOf,
          lesserOf: marketingLevy.value?.lesserOf,
          marketReviewCollar: null,
          marketReviewCap: null,
          marketReviewDate: null,
          amount: null,
          value: null,
        } satisfies Partial<MarketingLevyReviewTable>
      }),
      initialItemCondition: () => !increaseAsBaseRent.value,
      watchInitialItem: true,
      evaluate: (_, index) => {
        return {
          amount: computed(() => {
            // If `asBaseRent` is true, the `amount` will be evaluated in the BE.
            if (increaseAsBaseRent.value) return undefined

            // For now, only support FixedAmount and FixedPercentage
            if (
              !method.value ||
              !increase.value ||
              !['FixedAmount', 'FixedPercentage'].includes(method.value)
            )
              return undefined

            const previousAmountPath =
              index === 0
                ? 'marketingLevy.data[0].marketingLevyAmount'
                : joinPath(joinDataPath(index - 1), 'amount')

            switch (method.value) {
              case 'FixedAmount':
                return `${previousAmountPath} + ${increase.value}`
              case 'FixedPercentage':
                return `${previousAmountPath} + (${previousAmountPath} * ${increase.value})`
            }
          }),
        }
      },
    })

  // Reset the `amount` and `value` when `asBaseRent` changes
  // When `asBaseRent` is true, the evaluation will be done in the BE.
  watch(increaseAsBaseRent, () => {
    batchUpdate({ amount: null, value: null, method: null })
  })

  const { generate } = useDatesGenerator(
    {
      startDate: computed(() =>
        leaseStartDate.value
          ? dayjs.utc(leaseStartDate.value).add(1, 'year').toISOString()
          : null,
      ),
      endDate: leaseEndDate,
    },
    {
      onAdd: (index, { startDate }) => {
        addOrUpdate(index, {
          reviewDate: startDate.toISOString(),
          __status: REPEATER_ITEM_STATUS.GENERATED,
        })
      },
      onRemove: (index) => {
        const item = data.value[index]
        if (item) remove(item, index)
      },
      maxIterations: 200,
    },
  )

  watch(
    [leaseStartDate, leaseEndDate],
    () => {
      generate(YearType.LEASE_YEAR)
    },
    { immediate: true },
  )

  return {
    leaseStartDate,
    leaseEndDate,
    filteredData: computed(() =>
      filteredData.value.map(convertMarketingLevyToReviewTable),
    ),
  }
}
